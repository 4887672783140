import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    data: PropTypes.shape({
        title: PropTypes.any,
        paragraph: PropTypes.any,
    }).isRequired,
    children: PropTypes.node,
    tag: PropTypes.oneOf(['h1', 'h2', 'h3']),
};

const defaultProps = {
    children: null,
    tag: 'h2',
};

const SectionHeader = (props) => {
    const { className, data, children, tag, ...restProps } = props;

    const classes = classNames('section-header', className);

    const Component = tag;

    return (
        <React.Fragment>
            {(data.title || data.paragraph) && (
                <div {...restProps} className={classes}>
                    <div className="container-xs">
                        {children}
                        {data.title && (
                            <Component
                                className={classNames('mt-0', data.paragraph ? 'mb-16' : 'mb-0')}
                            >
                                {data.title}
                            </Component>
                        )}
                        {data.paragraph && <p className="m-0">{data.paragraph}</p>}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
