import LayoutDefault from '../../layouts/LayoutDefault';
import About from './About';

export const AboutRoutes = [
    {
        path: '/about-us',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <About />,
            },
        ],
    },
];
