import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';
import { currencyFormat } from '../../utils/intl';
import { toUpperCaseFirstChar } from '../../utils/String';
import { useTranslation } from 'react-i18next';

const propTypes = {
    ...SectionTilesProps.types,
    pricingSwitcher: PropTypes.bool,
    pricingSlider: PropTypes.bool,
};

const defaultProps = {
    ...SectionTilesProps.defaults,
    pricingSwitcher: false,
    pricingSlider: false,
};

const Pricing = (pricingProps) => {
    const { t: translate } = useTranslation();
    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
        pricingSwitcher,
        pricingSlider,
        ...props
    } = pricingProps;

    const [plans, setPlans] = useState({
        freemium: {
            key: 'freemium',
            name: 'pricing.freemium.name',
            price_monthly: 0,
            price_annually: 0,
            description: 'pricing.freemium.description',
            button: 'pricing.freemium.button',
            button_link: '/download',
            offers: [
                'pricing.freemium.offer_1',
                'pricing.freemium.offer_2',
                'pricing.freemium.offer_3',
            ],
        },
        standard: {
            key: 'standard',
            name: 'pricing.standard.name',
            price_monthly: 6,
            price_annually: 72,
            description: 'pricing.standard.description',
            button: 'pricing.standard.button',
            button_link: '/download',
            offers: [
                // 'pricing.standard.offer_1',
                'pricing.standard.offer_2',
                'pricing.standard.offer_3',
                'pricing.standard.offer_4',
            ],
        },
        enterprise: {
            key: 'enterprise',
            name: 'pricing.enterprise.name',
            // price_monthly: 8.5,
            // price_annually: 102,
            description: 'pricing.enterprise.description',
            button: 'pricing.enterprise.button',
            button_link: '/contact-us',
            offers: [
                'pricing.enterprise.offer_1',
                'pricing.enterprise.offer_2',
                'pricing.enterprise.offer_3',
                'pricing.enterprise.offer_4',
            ],
        },
    });
    const [switcherValue, setSwitcherValue] = useState('price_monthly');

    const handlePricingSwitch = (e) => {
        setSwitcherValue(e.target.checked ? 'price_annually' : 'price_monthly');
    };

    const outerClasses = classNames(
        'pricing section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'pricing-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: translate('pricing.sectionHeader.title'),
        paragraph: '',
    };

    const renderPriceCard = (plan) => {
        return (
            <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                    <div className="pricing-item-content">
                        <div className="pricing-item-header pb-24 mb-24">
                            <div className="text-lg font-bold">
                                {plan?.name ? translate(plan?.name) : ''}
                            </div>
                            <div className="pricing-item-price ">
                                <span className="pricing-item-price-amount h1">
                                    {plan[switcherValue] !== undefined
                                        ? currencyFormat(plan[switcherValue])
                                        : ''}
                                </span>
                            </div>
                            <div className="pricing-item-features-title  text-xs text-color-low mb-24">
                                {plan.key === 'enterprise'
                                    ? ''
                                    : translate('pricing.per_user_per_month')}
                            </div>
                            <div className="text-xs text-color-high">
                                {plan?.description ? translate(plan?.description) : ''}
                            </div>
                        </div>
                        <div className="pricing-item-features mb-40">
                            <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                                {translate('pricing.whatIsIncluded')}
                            </div>

                            <ul className="pricing-item-features-list list-reset text-xs mb-32">
                                {plan.offers &&
                                    plan.offers.map((offer, index) => {
                                        return (
                                            <li
                                                className="is-checked"
                                                key={`${plan.name}-${index}`}
                                            >
                                                {toUpperCaseFirstChar(translate(offer))}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </div>
                    <div className="pricing-item-cta mb-8">
                        <Button tag="a" color="primary" wide href={plan.button_link}>
                            {translate(plan.button)}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    {pricingSwitcher && (
                        <div className="pricing-switcher center-content">
                            <Switch
                                checked={switcherValue === 'price_annually'}
                                onChange={handlePricingSwitch}
                                rightLabel={translate('pricing.billing.price_annually')}
                            >
                                {translate('pricing.billing.price_monthly')}
                            </Switch>
                        </div>
                    )}

                    <div className={tilesClasses}>
                        {renderPriceCard(plans.freemium)}
                        {renderPriceCard(plans.standard)}
                        {renderPriceCard(plans.enterprise)}
                    </div>
                </div>
            </div>
        </section>
    );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
