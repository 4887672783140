import React from 'react';
import GenericSection from '../../components/sections/GenericSection';
import SectionHeader from '../../components/sections/partials/SectionHeader';
import { Trans } from 'react-i18next';
import AccordionItem from '../../components/elements/AccordionItem';
import Accordion from '../../components/elements/Accordion';

const FAQPage = () => {
    const faqList = [
        {
            title: 'faq.do_i_need_to_register.title',
            content: 'faq.do_i_need_to_register.content',
            image: '',
        },
        {
            title: 'faq.how_to_create_an_organization.title',
            content: 'faq.how_to_create_an_organization.content',
            image: '',
        },
        {
            title: 'faq.how_to_join_an_organization.title',
            content: 'faq.how_to_join_an_organization.content',
            image: '',
        },
        {
            title: 'faq.how_to_manage_my_employees.title',
            content: 'faq.how_to_manage_my_employees.content',
            children: [
                'faq.how_to_manage_my_employees.item_1',
                'faq.how_to_manage_my_employees.item_2',
                'faq.how_to_manage_my_employees.item_3',
            ],
            image: '',
        },
        {
            title: 'faq.what_are_the_existing_roles.title',
            content: 'faq.what_are_the_existing_roles.content',
            listClass: 'list-disc',
            children: [
                'faq.what_are_the_existing_roles.item_1',
                'faq.what_are_the_existing_roles.item_2',
                'faq.what_are_the_existing_roles.item_3',
            ],
        },
        {
            title: 'faq.how_to_create_a_container.title',
            children: [
                'faq.how_to_create_a_container.item_1',
                'faq.how_to_create_a_container.item_2',
                'faq.how_to_create_a_container.item_3',
                'faq.how_to_create_a_container.item_4',
            ],
            image: '',
        },
        {
            title: 'faq.how_to_change_the_status_of_a_packet.title',
            children: [
                'faq.how_to_change_the_status_of_a_packet.item_1',
                'faq.how_to_change_the_status_of_a_packet.item_2',
                'faq.how_to_change_the_status_of_a_packet.item_3',
            ],
            image: '',
        },
        {
            title: 'faq.how_to_change_the_status_of_an_order.title',
            content: 'faq.how_to_change_the_status_of_an_order.content',
            listClass: 'list-disc',
            children: [
                'faq.how_to_change_the_status_of_an_order.item_1',
                'faq.how_to_change_the_status_of_an_order.item_2',
                'faq.how_to_change_the_status_of_an_order.item_3',
            ],
            image: '',
        },
        {
            title: 'faq.how_to_share_the_invoice_of_an_order.title',
            children: [
                'faq.how_to_share_the_invoice_of_an_order.item_1',
                'faq.how_to_share_the_invoice_of_an_order.item_2',
                'faq.how_to_share_the_invoice_of_an_order.item_3',
            ],
            image: '',
        },
        {
            title: 'faq.how_to_share_package_information.title',
            children: [
                'faq.how_to_share_package_information.item_1',
                'faq.how_to_share_package_information.item_2',
                'faq.how_to_share_package_information.item_3',
                'faq.how_to_share_package_information.item_4',
            ],
            image: '',
        },
    ];

    const renderChildren = (item, children) => {
        return (
            <ul className={item.listClass ? `${item.listClass} mb-5` : 'list-decimal mb-5'}>
                {children.map((child) => {
                    return (
                        <li key={child} className={'mb-5'}>
                            <Trans i18nKey={child} />
                        </li>
                    );
                })}
            </ul>
        );
    };
    return (
        <React.Fragment>
            <GenericSection>
                <div className="container-md">
                    <SectionHeader
                        data={{
                            title: <Trans i18nKey={'faq.title'} />,
                            paragraph: '',
                        }}
                        className="center-content"
                    />
                    <Accordion>
                        {faqList.map((item, index) => {
                            return (
                                <React.Fragment key={`${index}-${item.title}`}>
                                    <AccordionItem title={<Trans i18nKey={item.title} />}>
                                        {item.content && <Trans i18nKey={item.content} />}
                                        {item.children && renderChildren(item, item.children)}
                                    </AccordionItem>
                                </React.Fragment>
                            );
                        })}
                    </Accordion>
                </div>
            </GenericSection>
        </React.Fragment>
    );
};

export default FAQPage;
