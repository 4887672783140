import React from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import { AboutRoutes } from '../views/about/routes';
import { AppLinkRoutes } from '../views/appStoreLink/routes';
import { AuthRoutes } from '../views/auth/routes';
import { FAQRoutes } from '../views/faq/routes';
import { HomeRoutes } from '../views/home/routes';
import { SecondaryRoutes } from '../views/secondary/routes';
import { ContactUsRoutes } from '../views/contactUs/routes';
import { PrivacyRoutes } from '../views/privacy/routes';
import { PricingRoutes } from '../views/pricing/routes';

export const AppRoutes = () => {
    return useRoutes([
        ...HomeRoutes,
        ...PricingRoutes,
        ...AuthRoutes,
        ...AboutRoutes,
        ...AppLinkRoutes,
        ...FAQRoutes,
        ...SecondaryRoutes,
        ...ContactUsRoutes,
        ...PrivacyRoutes,
    ]);
};
