import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { useMountEffect } from '../hooks/useOnMount.hook';
import { useDeepCompareEffect } from '../hooks/useDeepCompareEffect';
import { useLocation } from 'react-router-dom';

/*class ScrollReveal extends React.Component {

  state = {
    viewportHeight: window.innerHeight,
    revealEl: []
  };

  checkComplete = () => {
    return this.state.revealEl.length <= document.querySelectorAll('[class*=reveal-].is-revealed').length;
  };

  elementIsVisible = (el, offset) => {
    return (el.getBoundingClientRect().top <= this.state.viewportHeight - offset);
  };

  revealElements = () => {
    if (this.checkComplete()) return;
    for (let i = 0; i < this.state.revealEl.length; i++) {
      let el = this.state.revealEl[i];
      let revealDelay = el.getAttribute('data-reveal-delay');
      let revealOffset = (el.getAttribute('data-reveal-offset') ? el.getAttribute('data-reveal-offset') : '200');
      let listenedEl = (el.getAttribute('data-reveal-container') ? el.closest(el.getAttribute('data-reveal-container')) : el);
      if (this.elementIsVisible(listenedEl, revealOffset) && !el.classList.contains('is-revealed')) {
        if (revealDelay && revealDelay !== 0) {
          setTimeout(function () {
            el.classList.add('is-revealed');
          }, revealDelay);
        } else {
          el.classList.add('is-revealed');
        }
      }
    }
  };

  init = () => {
    setTimeout(() => {
      this.setState({ revealEl: document.querySelectorAll('[class*=reveal-]') }, () => {
        if (!this.checkComplete()) {
          window.addEventListener('scroll', this.handleScroll);
          window.addEventListener('resize', this.handleResize);
        }
        this.revealElements();
      });
    }, 100);
  };

  handleListeners = () => {
    if (!this.checkComplete()) return;
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  };

  handleScroll = throttle(() => {
    this.handleListeners();
    this.revealElements();
  }, 30);

  handleResize = throttle(() => {
    this.setState({ viewportHeight: window.innerHeight }, () => {
      this.handleListeners();
      this.revealElements();
    });
  }, 30);

  render() {
    return (
      <React.Fragment>
        {this.props.children()}
      </React.Fragment>
    );
  }
}

ScrollReveal.propTypes = {
  children: PropTypes.func.isRequired
};

export default ScrollReveal;*/

const ScrollReveal = (props) => {
    let location = useLocation();
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [revealEl, setRevealEl] = useState([]);

    const checkComplete = () => {
        return revealEl.length <= document.querySelectorAll('[class*=reveal-].is-revealed').length;
    };

    const elementIsVisible = (el, offset) => {
        return el.getBoundingClientRect().top <= viewportHeight - offset;
    };

    const revealElements = () => {
        if (checkComplete()) return;
        for (let i = 0; i < revealEl.length; i++) {
            let el = revealEl[i];
            let revealDelay = el.getAttribute('data-reveal-delay');
            let revealOffset = el.getAttribute('data-reveal-offset')
                ? el.getAttribute('data-reveal-offset')
                : '200';
            let listenedEl = el.getAttribute('data-reveal-container')
                ? el.closest(el.getAttribute('data-reveal-container'))
                : el;
            if (
                elementIsVisible(listenedEl, revealOffset) &&
                !el.classList.contains('is-revealed')
            ) {
                if (revealDelay && revealDelay !== 0) {
                    setTimeout(function () {
                        el.classList.add('is-revealed');
                    }, revealDelay);
                } else {
                    el.classList.add('is-revealed');
                }
            }
        }
    };

    const init = () => {
        setTimeout(() => {
            setRevealEl(document.querySelectorAll('[class*=reveal-]'));
        }, 100);
    };

    const handleListeners = () => {
        if (!checkComplete()) return;
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
    };

    const handleScroll = throttle(() => {
        handleListeners();
        revealElements();
    }, 30);

    const handleResize = throttle(() => {
        setViewportHeight(window.innerHeight);
    }, 30);

    useMountEffect(() => {
        document.body.classList.add('is-loaded');
        init();
    });

    // Route change
    useEffect(() => {
        if (location) {
            init();
        }
    }, [location?.pathname]);

    useDeepCompareEffect(() => {
        if (viewportHeight) {
            handleListeners();
            revealElements();
        }
    }, [viewportHeight]);

    useDeepCompareEffect(() => {
        if (revealEl) {
            if (!checkComplete()) {
                window.addEventListener('scroll', handleScroll);
                window.addEventListener('resize', handleResize);
            }
            revealElements();
        }
    }, [revealEl]);

    return <React.Fragment>{props.children()}</React.Fragment>;
};

ScrollReveal.propTypes = {
    children: PropTypes.func.isRequired,
};

export default ScrollReveal;
