import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import BdLogistics from '../../assets/images/bf-logistics.jpg';
import PytangoLogo from '../../assets/images/icon_pytango.png';
import KehtschahTransportLogistique from '../../assets/images/log_Kehtschah_Transport_Logistique.png';
import STExpressGroupage from '../../assets/images/logo_ST_Express_Groupage.png';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
    ...SectionProps.types,
};

const defaultProps = {
    ...SectionProps.defaults,
};

const Clients = (clientProps) => {
    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        ...restProps
    } = clientProps;

    const { t } = useTranslation();
    const outerClasses = classNames(
        'clients section reveal-fade',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'clients-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const currentClients = [
        { name: 'Pytango', logo: PytangoLogo, width: 100, height: 31 },
        { name: 'BdLogistics', logo: BdLogistics, width: 150, height: 31 },
        { name: 'ST Express Groupage', logo: STExpressGroupage, width: 150, height: 31 },
        {
            name: 'Kehtschah Transport et Logistique',
            logo: KehtschahTransportLogistique,
            width: 100,
            height: 31,
        },
    ];
    const sectionHeader = {
        title: t('trust_us'),
        paragraph: t('app_description_1'),
    };

    return (
        <section {...restProps} className={outerClasses}>
            <div className="container-lg">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />

                    <ul className="list-reset">
                        {currentClients.map((row) => (
                            <li className="reveal-from-bottom" key={row.name}>
                                <Image
                                    src={row.logo}
                                    alt={row.name}
                                    width={row.width}
                                    height={row.height}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;
