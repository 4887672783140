import React from 'react';
import SignupForm from '../../components/sections/SignupForm';

class Signup extends React.Component {
    render() {
        return <SignupForm className="illustration-section-01" />;
    }
}

export default Signup;
