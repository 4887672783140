import React, { useEffect, useRef } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { Outlet, useLocation } from 'react-router-dom';

const LayoutDefault = ({ children }) => {
    const location = useLocation();
    const currentPage = useRef(null);

    // scroll to the  top on page change
    useEffect(() => {
        if (location && location.pathname !== currentPage.current) {
            currentPage.current = location.pathname;
            window.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <React.Fragment>
            <Header navPosition="right" className="reveal-from-top" hideSignin={false} />
            <main className="site-content">
                <Outlet />
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default LayoutDefault;
