/**
 * Capitalize the first letter of a string and lowercase the rest
 * @param first
 * @param rest
 */
export const toUpperCaseFirstChar = (value) => {
    if (value) {
        const [first, ...rest] = value.trim();
        return first.toUpperCase() + rest.join('').toLowerCase();
    }
    return '';
};

export const getUserName = (user) => {
    return `${toUpperCaseFirstChar(user?.given_name || '')} ${toUpperCaseFirstChar(
        user?.family_name || ''
    )}`;
};
