import LayoutDefault from '../../layouts/LayoutDefault';
import PrivacyPage from './Privacy.page';
import TermsConditionsPage from './TermsConditions.page';

export const PrivacyRoutes = [
    {
        path: '/privacy',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <PrivacyPage />,
            },
        ],
    },
    {
        path: '/terms_and_conditions',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <TermsConditionsPage />,
            },
        ],
    },
];
