import React from 'react';
import GenericSection from '../../components/sections/GenericSection';
import SectionHeader from '../../components/sections/partials/SectionHeader';
import { Trans } from 'react-i18next';

const About = () => {
    const aboutUsList = [
        {
            title: 'about_us.our_job.title',
            paragraph: 'about_us.our_job.paragraph',
        },
        {
            title: 'about_us.our_ambitions.title',
            paragraph: 'about_us.our_ambitions.paragraph',
        },
        {
            title: 'about_us.our_challenges.title',
            paragraph: 'about_us.our_challenges.paragraph',
        },
        {
            title: 'about_us.our_values.title',
            paragraph: 'about_us.our_values.paragraph',
        },
    ];

    return (
        <React.Fragment>
            <GenericSection>
                {aboutUsList.map((item) => {
                    return (
                        <React.Fragment key={item.title}>
                            <SectionHeader
                                data={{
                                    title: <Trans i18nKey={item.title} />,
                                    paragraph: <Trans i18nKey={item.paragraph} />,
                                }}
                                className="center-content"
                            />
                        </React.Fragment>
                    );
                })}
            </GenericSection>
        </React.Fragment>
    );
};

export default About;
