import LayoutDefault from '../../layouts/LayoutDefault';
import Secondary from './Secondary';

export const SecondaryRoutes = [
    {
        path: '/secondary',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <Secondary />,
            },
        ],
    },
];
