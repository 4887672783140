import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool,
};

const defaultProps = {
    ...SectionProps.defaults,
    split: false,
};

const Cta = (ctaProps) => {
    const { t } = useTranslation();
    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        split,
        ...props
    } = ctaProps;

    const outerClasses = classNames(
        'cta section center-content-mobile',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'cta-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
    );

    return (
        <section {...props} className={outerClasses}>
            <div className="container-lg">
                <div className={innerClasses}>
                    {/* <div className="cta-slogan">
                        <h3 className="m-0">{t('download_the_app')}</h3>
                    </div>*/}
                    <div className="cta-action">
                        <ul className="list-reset header-nav-right">
                            <li>
                                <Link
                                    to="/fretoffceapps/"
                                    className="button  button-wide-mobile button-sm"
                                >
                                    {t('download_the_app')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
