import React from 'react';
import GenericSection from '../../components/sections/GenericSection';
import { Trans } from 'react-i18next';
import SectionHeader from '../../components/sections/partials/SectionHeader';
import PrivacyPolicyComponent from './components/privacyPolicy.component';
import EulaComponent from './components/eula.component';

const PrivacyPage = () => {
    return (
        <React.Fragment>
            <GenericSection>
                <div className="container-md">
                    <SectionHeader
                        data={{
                            title: <Trans i18nKey={'privacy.title'} />,
                            paragraph: '',
                        }}
                        className="center-content"
                    />
                    {/*End-User License Agreement (EULA) of FretOffice*/}
                    <EulaComponent />
                    <PrivacyPolicyComponent />
                </div>
            </GenericSection>
        </React.Fragment>
    );
};

export default PrivacyPage;
