import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppLogo from './../../../assets/images/logo.png';
import Image from '../../elements/Image';

const Logo = ({ className, ...props }) => {
    const classes = classNames('brand', className);

    return (
        <div {...props} className={classes}>
            <Link to="/" style={{ color: 'white', textDecoration: 'none' }} className={'flex'}>
                <Image src={AppLogo} alt="Surface" width={75} height={75} />
                <div className={'pt-5 font-bold'}>FretOffice</div>
            </Link>
            {/* {<h1 className="m-0">

               <Link to="/">
                    <Image src={AppLogo} alt="Surface" width={99} height={99} />
                </Link>
            </h1>*/}
        </div>
    );
};

export default Logo;
