import LayoutSignin from '../../layouts/LayoutSignin';
import Login from './Login';
import Signup from './Signup';

const LoginRoute = {
    path: '/login',
    element: <LayoutSignin />,
    children: [
        {
            path: '',
            element: <Login />,
        },
    ],
};

const SignupRoute = {
    path: '/signup',
    element: <LayoutSignin />,
    children: [
        {
            path: '',
            element: <Signup />,
        },
    ],
};

export const AuthRoutes = [LoginRoute, SignupRoute];
