export const currencyFormat = (value, currency, local) => {
    return new Intl.NumberFormat(local || 'fr-FR', {
        style: 'currency',
        currency: currency || 'EUR',
    }).format(value);
};

export const percentFormat = (value, local) => {
    return new Intl.NumberFormat(local || 'fr-FR', {
        style: 'percent',
    }).format(value);
};

export const dateFormat = (date, local) => {
    return new Intl.DateTimeFormat(local || 'fr-FR').format(date);
};
