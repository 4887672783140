import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

export const deepCompareEquals = (a, b) => isEqual(a, b);

export const deepCompareNoEquals = (a, b) => !isEqual(a, b);

export const useDeepCompareEffect = (callback, dependencies: any[]) => {
    const isFirst = useRef(true);
    const prevDep = useRef(dependencies);
    useEffect(() => {
        const isSame = prevDep.current.every((obj, index) => deepCompareEquals(obj, dependencies[index]));
        if (isFirst.current || !isSame) {
            callback();
        }
        isFirst.current = false;
        prevDep.current = dependencies;
    }, [dependencies]);
};
