import LayoutDefault from '../../layouts/LayoutDefault';
import FAQPage from './FAQ';

export const FAQRoutes = [
    {
        path: '/faqs',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <FAQPage />,
            },
        ],
    },
];
