import React from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import WhatsappIcon from './../../assets/images/whatsapp.svg';
import GenericSection from '../../components/sections/GenericSection';

const ContactUs = () => {
    const email = 'info@fretoffice.com';
    const mobile = '+33 6 70 74 06 47';

    const sendMail = () => {
        window.location.href = `mailto:${email}?subject=`;
    };
    const call = () => {
        window.location.href = `tel:${mobile}=`;
    };
    return (
        <React.Fragment>
            <GenericSection>
                <div className="flex flex-col items-center justify-around h-60 cursor-pointer ">
                    <button className="button flex" onClick={call}>
                        <img src={WhatsappIcon} className={'mr-8'} width={22} alt={'whatsapp'} />
                        <span style={{ paddingTop: 0 }}>{mobile}</span>
                    </button>

                    <button className={'button flex cursor-pointer '} onClick={sendMail}>
                        <EnvelopeIcon className={'h-5 w-5 text-color-primary mr-8'} />
                        <span className={'lowercase'} style={{ paddingTop: 2 }}>
                            {email}
                        </span>
                    </button>
                </div>
            </GenericSection>
        </React.Fragment>
    );
};

export default ContactUs;
