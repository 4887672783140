export const customerTestimonial = [
    {
        name: 'Alek F.',
        appName: 'Best Friends Logistics',
        logo: require('./../../../assets/images/bf-logistics.jpg'),
        comment:
            "Avec l'application, c'est plus simple de gérer ma grande liste de clients. En plus de cela, je contrôle plus facilement les paquets, ce qui était très pénible à faire manuellement.",
    },
    {
        name: 'Elise N.',
        appName: 'Best Friends Logistics',
        logo: require('./../../../assets/images/bf-logistics.jpg'),
        comment:
            "La plateforme facilite l'enregistrement des paquets et le client reçoit directement sa facture par Whatsapp ou par Email.",
    },
];
