import {
    BanknotesIcon,
    CircleStackIcon,
    ListBulletIcon,
    MagnifyingGlassIcon,
    PresentationChartBarIcon,
    QrCodeIcon,
    UserGroupIcon,
    UsersIcon
} from '@heroicons/react/24/outline'
import FeatureTileIcon01 from './../../../assets/images/feature-tile-icon-01.svg';
import FeatureTileIcon02 from './../../../assets/images/feature-tile-icon-02.svg';
import FeatureTileIcon03 from './../../../assets/images/feature-tile-icon-03.svg';
import FeatureTileIcon04 from './../../../assets/images/feature-tile-icon-04.svg';
import FeatureTileIcon05 from './../../../assets/images/feature-tile-icon-05.svg';
import FeatureTileIcon06 from './../../../assets/images/feature-tile-icon-06.svg';

const iconClasses="h-6 w-6 text-white";
export const appFeatures = [
    {
        title: 'feature_1.title',
        description: 'feature_1.description',
        icon: FeatureTileIcon01,
        renderIcon:()=><ListBulletIcon className={iconClasses}/>
    },
    {
        title: 'feature_2.title',
        description: 'feature_2.description',
        icon: FeatureTileIcon02,
        renderIcon:()=><UsersIcon className={iconClasses}/>
    },
    {
        title: 'feature_3.title',
        description: 'feature_3.description',
        icon: FeatureTileIcon03,
        renderIcon:()=><CircleStackIcon className={iconClasses}/>
    },
    {
        title: 'feature_4.title',
        description: 'feature_4.description',
        icon: FeatureTileIcon04,
        renderIcon:()=><BanknotesIcon className={iconClasses}/>
    },
    {
        title: 'feature_5.title',
        description: 'feature_5.description',
        icon: FeatureTileIcon05,
        renderIcon:()=><QrCodeIcon className={iconClasses}/>
    },
    {
        title: 'feature_6.title',
        description: 'feature_6.description',
        icon: FeatureTileIcon06,
        renderIcon:()=><PresentationChartBarIcon className={iconClasses}/>
    },
    {
        title: 'feature_7.title',
        description: 'feature_7.description',
        icon: FeatureTileIcon06,
        renderIcon:()=><MagnifyingGlassIcon className={iconClasses}/>
    },
    {
        title: 'feature_8.title',
        description: 'feature_8.description',
        icon: FeatureTileIcon06,
        renderIcon:()=><UserGroupIcon className={iconClasses}/>
    },
];
