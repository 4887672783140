import LayoutDefault from '../../layouts/LayoutDefault';
import PricingPage from './pricing.page';

export const PricingRoutes = [
    {
        path: '/pricing',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <PricingPage />,
            },
        ],
    },
];
