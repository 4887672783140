import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import GenericSection from '../../components/sections/GenericSection';
import Carousel3D from '../../components/elements/3d-carousel/Carousel3D';
import CreateOrderImage_1 from './../../assets/images/create-order-1.png';
import AppHomeImage from './../../assets/images/app-home.png';
import OrderReviewImage from './../../assets/images/order-review.png';
import OrderDetailsImage from './../../assets/images/order-details.png';
import FretoffcieStatsImage from './../../assets/images/fretoffcie-stats.png';
import './styles/styles.scss';
import AppstoreButton from '../../components/elements/Appstore.button';
import GooglePlayButton from '../../components/elements/GooglePlay.button';

const AppStoreLink = () => {
    const appScreenShots = [
        { src: AppHomeImage, label: 'AppHomeImage' },
        { src: CreateOrderImage_1, label: 'FretOffceNeworder' },
        { src: OrderReviewImage, label: 'OrderReviewImage' },
        { src: OrderDetailsImage, label: 'OrderDetailsImage' },
        { src: FretoffcieStatsImage, label: 'FretoffcieStatsImage' },
    ];

    const openLink = (url) => {
        /* if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
            window.location.href = url;
            // window.location.assign(url);
        }
        if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            window.location.href = url;
        } */
        // window.open(url, '_blank');
        console.log('click: ', url);
        window.open(url, '_blank');
        //  let windowReference = window.open();

        //  windowReference.location = url;

        // window.location.assign(url);
    };

    return (
        <React.Fragment>
            <GenericSection>
                <div className="flex flex-col md:flex-row items-center justify-around md:mt-0">
                    <AppstoreButton
                        /* onPress={() => {
                                console.log(
                                    'prod lien exemple: https://apps.apple.com/de/app/pytango/id1158707511'
                                );
                                window.open('https://testflight.apple.com/join/BYdrycWm');
                            }}*/
                        onPress={() =>
                            openLink('https://apps.apple.com/us/app/fretoffice/id1642605728')
                        }
                    />

                    <GooglePlayButton
                        onPress={() =>
                            openLink(
                                'https://play.google.com/store/apps/details?id=com.fretoffice&hl=de&gl=DE'
                            )
                        }
                    />
                </div>
            </GenericSection>
            <GenericSection>
                <div className={'carousel-content'}>
                    <div className="iphone-case" />
                    <Carousel3D
                        autoplay={false}
                        leftButton={<ChevronLeftIcon className="h-6 w-6 text-green-500" />}
                        rightButton={<ChevronRightIcon className="h-6 w-6 text-green-500" />}
                        slides={appScreenShots.map((item, index) => {
                            return (
                                <img
                                    src={item.src}
                                    width={300}
                                    height={758}
                                    alt={item.label}
                                    key={`${index}-${item.label}`}
                                />
                            );
                        })}
                    />
                </div>
            </GenericSection>
        </React.Fragment>
    );
};

export default AppStoreLink;
