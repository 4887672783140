import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { useTranslation } from 'react-i18next';
import { customerTestimonial } from './constants/customer-testimonial';

const propTypes = {
    ...SectionTilesProps.types,
};

const defaultProps = {
    ...SectionTilesProps.defaults,
};

const Testimonial = (props) => {
    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: t('testimonial.title'),
        paragraph: t('testimonial.description'),
    };

    return (
        <section {...restProps} className={outerClasses}>
            <div className="container-lg">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>
                        {customerTestimonial.map((item) => {
                            return (
                                <div
                                    className="tiles-item reveal-from-bottom"
                                    data-reveal-container=".tiles-wrap"
                                    data-reveal-delay="200"
                                    key={item.name}
                                >
                                    <div className="tiles-item-inner has-shadow">
                                        <div className="testimonial-item-header mb-16">
                                            <div className="testimonial-item-image">
                                                <img
                                                    src={item.logo}
                                                    alt="Testimonial 02"
                                                    width={72}
                                                    height={72}
                                                />
                                            </div>
                                        </div>
                                        <div className="testimonial-item-content">
                                            <p className="text-sm mb-0">{item.comment}</p>
                                        </div>
                                        <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                                            <span className="testimonial-item-name">
                                                {item.name}
                                            </span>
                                            <span className="text-color-low"> - </span>
                                            <span className="testimonial-item-link">
                                                <a href="#0">{item.appName}</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
