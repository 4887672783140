import React from 'react';
import Header from '../components/layout/Header';
import { Outlet } from 'react-router-dom';

const LayoutSignin = ({ children }) => (
    <React.Fragment>
        <Header navPosition="right" hideNav />
        <main className="site-content">
            <Outlet />
        </main>
    </React.Fragment>
);

export default LayoutSignin;
