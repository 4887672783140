import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const FooterNav = ({ className, ...props }) => {
    const classes = classNames('footer-nav', className);
    const { t } = useTranslation();

    const pages = [
        { href: '/about-us', label: t('footer.about_us') },
        { href: '/contact-us', label: t('footer.contact_us') },
        { href: '/faqs', label: t('footer.faq') },
        { href: '/privacy', label: t('footer.privacy') },
        { href: '/terms_and_conditions', label: t('footer.terms_and_conditions') },
    ];
    return (
        <nav {...props} className={classes}>
            <ul className="list-reset">
                {pages.map((item) => {
                    return (
                        <li key={item.href}>
                            <Link to={item.href}>{item.label}</Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default FooterNav;
