import LayoutDefault from '../../layouts/LayoutDefault';
import ContactUs from './ContactUs';

export const ContactUsRoutes = [
    {
        path: '/contact-us',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <ContactUs />,
            },
        ],
    },
];
