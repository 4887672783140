import LayoutDefault from '../../layouts/LayoutDefault';
import Home from './Home';

export const HomeRoutes = [
    {
        path: '/',
        element: <LayoutDefault />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
        ],
    },
];
