import React from 'react';
import { AppRoutes } from './routes/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts

// Views

const App = () => {
    return <ScrollReveal children={() => <AppRoutes />} />;
};
export default App;
