import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SectionHeader from './partials/SectionHeader';
import { appFeatures } from './constants/features';
import React from 'react';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';

const FretOfficeVideo = (props) => {
    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const outerClasses = classNames(
        'features-tiles section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: t('slogan_1'),
        paragraph: t('app_description_1'),
    };

    return (
        <section {...restProps} className={outerClasses}>
            <div className="container-lg">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>
                        <YoutubeEmbed embedId={'k7S1A8f0_ic'} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FretOfficeVideo;
