import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useMountEffect } from '../../hooks/useOnMount.hook';

const propTypes = {
    src: PropTypes.any.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
};

const defaultProps = {
    src: undefined,
    width: undefined,
    height: undefined,
    alt: undefined,
};

const Image = (props) => {
    // const [isLoaded, setIsLoaded] = useState(false);

    const image = useRef();

    const placeholderSrc = (w, h) => {
        return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
    };

    const handlePlaceholder = (img, placeholder) => {
        img.style.display = 'none';
        img.before(placeholder);
        placeholder.src = placeholderSrc(
            img.getAttribute('width') || 0,
            img.getAttribute('height') || 0
        );
        placeholder.width = img.getAttribute('width');
        placeholder.height = img.getAttribute('height');
        placeholder.style.opacity = '0';
        img.className && placeholder.classList.add(img.className);

        img.addEventListener('load', () => {
            placeholder.remove();
            img.style.display = '';
            // setIsLoaded(true);
        });
    };

    useMountEffect(() => {
        const placeholderImage = document.createElement('img');
        handlePlaceholder(image.current, placeholderImage);
    });

    const { className, src, width, height, alt, ...restProps } = props;

    return (
        <img
            {...restProps}
            ref={image}
            className={className}
            src={src}
            // width={width}
            //  height={height}
            style={{ width: width }}
            alt={alt}
        />
    );
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
