import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import { useMountEffect } from '../../hooks/useOnMount.hook';
import { useTranslation } from 'react-i18next';

const propTypes = {
    active: PropTypes.bool,
    navPosition: PropTypes.string,
    hideNav: PropTypes.bool,
    hideSignin: PropTypes.bool,
    bottomOuterDivider: PropTypes.bool,
    bottomDivider: PropTypes.bool,
};

const Header = (props) => {
    const {
        active = false,
        navPosition = '',
        hideNav = false,
        hideSignin = false,
        bottomOuterDivider = false,
        bottomDivider = false,
        className,
        ...restProps
    } = props;

    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const nav = React.createRef();
    const hamburger = React.createRef();
    const pages = [
        { href: '/about-us', label: t('header.about_us') },
        { href: '/pricing', label: t('header.pricing') },
        /* { href: '/fretoffceapps', label: 'Try it' },*/
    ];

    const openMenu = () => {
        document.body.classList.add('off-nav-is-active');
        nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
        // this.setState({ isActive: true })
        setIsActive(true);
    };

    const closeMenu = () => {
        document.body.classList.remove('off-nav-is-active');
        nav.current && (nav.current.style.maxHeight = null);
        setIsActive(false);
    };

    const keyPress = (e) => {
        isActive && e.keyCode === 27 && closeMenu();
    };

    const clickOutside = (e) => {
        if (!nav.current) return;
        if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
        closeMenu();
    };

    useMountEffect(() => {
        active && openMenu();
        document.addEventListener('keydown', keyPress);
        document.addEventListener('click', clickOutside);
        return () => {
            document.removeEventListener('keydown', keyPress);
            document.addEventListener('click', clickOutside);
            closeMenu();
        };
    });

    const classes = classNames(
        'site-header',
        bottomOuterDivider && 'has-bottom-divider',
        className
    );

    return (
        <header {...restProps} className={classes}>
            <div className={'container-lg'}>
                <div
                    className={classNames(
                        'site-header-inner',
                        bottomDivider && 'has-bottom-divider'
                    )}
                >
                    <Logo />
                    {!hideNav && (
                        <React.Fragment>
                            <button
                                ref={hamburger}
                                className="header-nav-toggle"
                                onClick={isActive ? closeMenu : openMenu}
                            >
                                <span className="screen-reader">Menu</span>
                                <span className="hamburger">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                            <nav
                                ref={nav}
                                className={classNames('header-nav', isActive && 'is-active')}
                            >
                                <div className="header-nav-inner">
                                    <ul
                                        className={classNames(
                                            'list-reset text-xxs',
                                            navPosition && `header-nav-${navPosition}`
                                        )}
                                    >
                                        {pages.map((page) => {
                                            return (
                                                <li key={page.href}>
                                                    <Link to={page.href}>{page.label}</Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </nav>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </header>
    );
};

Header.propTypes = propTypes;

export default Header;
