import React from 'react';
import LoginForm from '../../components/sections/LoginForm';

class Login extends React.Component {
    render() {
        return <LoginForm className="illustration-section-01" />;
    }
}

export default Login;
