import LayoutDefault from '../../layouts/LayoutDefault';
import AppStoreLink from './AppStoreLink';

export const AppLinkRoutes = [
    {
        path: '/download',
        element: <LayoutDefault />,
        children: [
            {
                path: '',
                element: <AppStoreLink />,
            },
        ],
    },
];
