import React from 'react';
const PrivacyPolicyComponent = () => {
    return (
        <React.Fragment>
            <h1>Privacy Policy for FretOffice</h1>

            <p>
                At FretOffice, one of our main priorities is the privacy of our visitors. This
                Privacy Policy document contains types of information that is collected and recorded
                by FretOffice and how we use it.
            </p>

            <p>
                If you have additional questions or require more information about our Privacy
                Policy, do not hesitate to contact us.
            </p>

            <h2>Log Files</h2>

            <p>
                FretOffice follows a standard procedure of using log files. These files log visitors
                when they use app. The information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                referring/exit pages, and possibly the number of clicks. These are not linked to any
                information that is personally identifiable. The purpose of the information is for
                analyzing trends, administering the app, tracking users' movement on the app, and
                gathering demographic information.
            </p>

            <h2>Our Advertising Partners</h2>

            <p>
                Some of advertisers in our app may use cookies and web beacons. Our advertising
                partners are listed below. Each of our advertising partners has their own Privacy
                Policy for their policies on user data. For easier access, we hyperlinked to their
                Privacy Policies below.
            </p>

            <ul>
                <li>
                    <p>Google</p>
                    <p>
                        <a href="https://policies.google.com/technologies/ads">
                            https://policies.google.com/technologies/ads
                        </a>
                    </p>
                </li>
            </ul>

            <h2>Privacy Policies</h2>

            <p>
                You may consult this list to find the Privacy Policy for each of the advertising
                partners of FretOffice.
            </p>

            <p>
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or
                Beacons that are used in their respective advertisements and links that appear on
                FretOffice. They automatically receive your IP address when this occurs. These
                technologies are used to measure the effectiveness of their advertising campaigns
                and/or to personalize the advertising content that you see on this app or other apps
                or websites.
            </p>

            <p>
                Note that FretOffice has no access to or control over these cookies that are used by
                third-party advertisers.
            </p>

            <h2>Third Party Privacy Policies</h2>

            <p>
                FretOffice's Privacy Policy does not apply to other advertisers or websites. Thus,
                we are advising you to consult the respective Privacy Policies of these third-party
                ad servers for more detailed information. It may include their practices and
                instructions about how to opt-out of certain options.
            </p>

            <h2>Children's Information</h2>

            <p>
                Another part of our priority is adding protection for children while using the
                internet. We encourage parents and guardians to observe, participate in, and/or
                monitor and guide their online activity.
            </p>

            <p>
                FretOffice does not knowingly collect any Personal Identifiable Information from
                children under the age of 13. If you think that your child provided this kind of
                information on our App, we strongly encourage you to contact us immediately and we
                will do our best efforts to promptly remove such information from our records.
            </p>

            <h2>Online Privacy Policy Only</h2>

            <p>
                This Privacy Policy applies only to our online activities and is valid for visitors
                to our App with regards to the information that they shared and/or collect in
                FretOffice. This policy is not applicable to any information collected offline or
                via channels other than this app.
            </p>

            <h2>Consent</h2>

            <p>
                By using our app, you hereby consent to our Privacy Policy and agree to its Terms
                and Conditions.
            </p>
        </React.Fragment>
    );
};

export default PrivacyPolicyComponent;
