import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Popover } from '@headlessui/react';
import { GlobeAltIcon } from '@heroicons/react/20/solid';
import { usePopper } from 'react-popper';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
    topOuterDivider: PropTypes.bool,
    topDivider: PropTypes.bool,
};

const defaultProps = {
    topOuterDivider: false,
    topDivider: false,
};

const Footer = (footerProps) => {
    const { className, topOuterDivider, topDivider, ...props } = footerProps;
    const { t, i18n } = useTranslation();
    let [referenceElement, setReferenceElement] = useState();
    let [popperElement, setPopperElement] = useState();
    let { styles, attributes } = usePopper(referenceElement, popperElement);
    const languages = { en: 'english', fr: 'french' };
    const classes = classNames(
        'site-footer invert-color center-content-mobile',
        topOuterDivider && 'has-top-divider',
        className
    );

    const handleLanguageChange = (event) => {
        // i18n.changeLanguage(event.target.value);
        i18n.changeLanguage(event);
    };

    const renderLanguageSelector = () => {
        return (
            <Popover>
                <Popover.Button
                    ref={setReferenceElement}
                    className={`flex focus:outline-none hover:text-white focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-opacity-75 `}
                >
                    <GlobeAltIcon className=" h-3 w-3 " />
                    <span className={'ml-4'}>{t(`language.${languages[i18n.language]}`)}</span>
                </Popover.Button>
                <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                    <div className="relative  bg-white py-2 rounded-md  ">
                        {Object.keys(languages).map((item) => (
                            <div
                                key={item}
                                className=" p-2 flex justify-center items-center cursor-pointer hover:bg-slate-300"
                                onClick={() => handleLanguageChange(item)}
                            >
                                <Popover.Button>
                                    <span className="text-sm text-gray-900">
                                        {t(`language.${languages[item]}`)}
                                    </span>
                                </Popover.Button>
                            </div>
                        ))}
                    </div>
                </Popover.Panel>
            </Popover>
        );
    };

    return (
        <footer {...props} className={classes}>
            <div className="container">
                <div className={classNames('site-footer-inner', topDivider && 'has-top-divider')}>
                    <div className="footer-top space-between text-xxs">
                        {/* <Logo />*/}
                        <FooterSocial />
                    </div>
                    <div className="footer-bottom space-between text-xxs">
                        <div className="flex items-center justify-center ">
                            {/*<FaGlobe/>*/}
                            {renderLanguageSelector()}
                            {/* <select
                                className="bg-transparent flex items-center justify-center"
                                value={i18n.language}
                                onChange={handleLanguageChange}
                            >
                                <option value="en">{t('language.english')}</option>
                                <option value="fr">{t('language.french')}</option>
                            </select> */}
                        </div>

                        <FooterNav />

                        <div className="footer-copyright">
                            &copy; FretOffice {new Date().getFullYear()},{' '}
                            {t('footer.all_rights_reserved')}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
