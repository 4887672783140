import React from 'react';
import Pricing from '../../components/sections/Pricing';

const PricingPage = () => {
    return (
        <React.Fragment>
            <Pricing topDivider className="has-bg-color-cut" pricingSwitcher={true} />
        </React.Fragment>
    );
};

export default PricingPage;
