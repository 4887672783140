import React from 'react';
import classNames from 'classnames';

import { Trans, useTranslation } from 'react-i18next';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';
import { appFeatures } from './constants/features';

const propTypes = {
    ...SectionTilesProps.types,
};

const defaultProps = {
    ...SectionTilesProps.defaults,
};

const FeaturesTiles = (props) => {
    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const outerClasses = classNames(
        'features-tiles section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: t('slogan_1'),
        paragraph: t('app_description_1'),
    };

    return (
        <section {...restProps} className={outerClasses}>
            <div className="container-lg">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>
                        {appFeatures.map((item) => {
                            return (
                                <div
                                    className="tiles-item reveal-from-bottom"
                                    data-reveal-container=".tiles-wrap"
                                    key={item.title}
                                >
                                    <div className="tiles-item-inner">
                                        <div className="features-tiles-item-header">
                                            <div
                                                className="features-tiles-item-image mb-16"
                                                style={{ padding: 28 }}
                                            >
                                                {/*<Image
                                                    src={item.icon}
                                                    alt="Features tile icon 01"
                                                    width={72}
                                                    height={72}
                                                />*/}
                                                {item.renderIcon()}
                                            </div>
                                        </div>
                                        <div className="features-tiles-item-content">
                                            <h4 className="mt-0 mb-8">{t(item.title)}</h4>
                                            <p className="m-0 text-sm">
                                                <Trans i18nKey={item.description} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
