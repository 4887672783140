import React from 'react';
import HeroFull from '../../components/sections/HeroFull';
import Clients from '../../components/sections/Clients';
import FeaturesTiles from '../../components/sections/FeaturesTiles';
import Testimonial from '../../components/sections/Testimonial';
import FretOfficeVideo from '../../components/sections/FretOfficeVideo';

const Home = () => {
    return (
        <React.Fragment>
            <HeroFull className="illustration-section-01" />
            <FretOfficeVideo />
            <Clients topDivider bottomDivider />
            {/*<FeaturesTabs />*/}
            <FeaturesTiles topDivider />
            <Testimonial topDivider bottomOuterDivider className="gradient-section" />
        </React.Fragment>
    );
};

export default Home;
