import React from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import FretOffceNeworder from './../../assets/images/fretoffce-neworder.png';
import AppstoreButton from '../elements/Appstore.button';
import GooglePlayButton from '../elements/GooglePlay.button';

const propTypes = {
    ...SectionProps.types,
};

const defaultProps = {
    ...SectionProps.defaults,
};

const HeroFull = (props) => {
    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner mb-10  mt-20 md:mt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        'flex flex-row flex-wrap'
    );

    const openLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        <section {...restProps} className={outerClasses}>
            <div className="container-lg">
                <div className={innerClasses}>
                    <div className="hero-content flex flex-col items-center   justify-center md:basis-1/2 xs:basis-full">
                        <h1 className="mt-0 mb-16 reveal-from-top " data-reveal-delay="150">
                            {t('slogan')}
                        </h1>
                        <div className="container-xs">
                            <p className="m-0 mb-32 reveal-from-top" data-reveal-delay="300">
                                <Trans i18nKey="app_description" />
                            </p>
                            {/*  <div className="reveal-from-top" data-reveal-delay="450">
                                <Button tag="a" color="primary" href="https://cruip.com/">
                                    Pricing and plans
                                </Button>
                            </div>*/}
                        </div>
                        <div className="flex flex-col md:flex-row items-center justify-around md:mt-10 w-full">
                            <AppstoreButton
                                onPress={() =>
                                    openLink(
                                        'https://apps.apple.com/us/app/fretoffice/id1642605728'
                                    )
                                }
                            />

                            <GooglePlayButton
                                onPress={() =>
                                    openLink(
                                        'https://play.google.com/store/apps/details?id=com.fretoffice&hl=de&gl=DE'
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div
                        className="flex flex-row-reverse hero-figure reveal-from-bottom md:basis-1/2 xs:basis-full"
                        data-reveal-delay="600"
                    >
                        <Image
                            className="m-0"
                            src={FretOffceNeworder}
                            alt="Hero"
                            width={400}
                            height={504}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
